import styled from "@emotion/styled"
import React from "react"
import SocialLinks from "./SocialLinks"

const StyledFooter = styled.footer`
  margin-top: 1.5rem;
`

const Container = styled.div`
  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    padding: 1.5rem 1rem;
  }
`

type FooterProps = {}

const Footer = ({}: FooterProps) => (
  <StyledFooter>
    <Container>
      <span>© {new Date().getFullYear()}</span>

      <SocialLinks />
    </Container>
  </StyledFooter>
)

Footer.defaultProps = {}

export default Footer
