import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import Navigation from "./Navigation"

const StyledHeader = styled.header`
  margin-bottom: 1.5rem;
`

const Container = styled.div`
  margin: 0 auto;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    padding: 1.5rem 1rem;
  }
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: 700;
`

type HeaderProps = {
  siteTitle: string
}

const Header = ({ siteTitle }: HeaderProps) => (
  <StyledHeader>
    <Container>
      <h1 style={{ margin: 0 }}>
        <StyledLink to="/">{siteTitle}</StyledLink>
      </h1>

      <Navigation />
    </Container>
  </StyledHeader>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
