import styled from "@emotion/styled"
import React from "react"

const StyledSocialLinks = styled.div``

const StyledLink = styled.a`
  color: black;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  transition: all 250ms ease;

  &:hover {
    border-bottom: 2px solid #000;
    padding-bottom: 4px;
  }

  &:not(:first-of-type) {
    margin-left: 20px;
  }
`

type SocialLinksProps = {}

const SocialLinks = ({}: SocialLinksProps) => (
  <StyledSocialLinks>
    <StyledLink
      href="https://www.linkedin.com/in/andersonryanc/"
      target="_blank"
    >
      linkedin
    </StyledLink>
    <StyledLink href="https://twitter.com/andersryanc" target="_blank">
      twitter
    </StyledLink>
    <StyledLink href="https://github.com/andersryanc" target="_blank">
      github
    </StyledLink>
  </StyledSocialLinks>
)

export default SocialLinks
