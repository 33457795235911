import styled from "@emotion/styled"

export const HeroSection = styled.div`
  padding-top: 4rem;
  max-width: 800px;
`

export const HeroEmoji = styled.p`
  font-size: 2rem;
`

export const HeroTitle = styled.h1`
  font-size: 2.8rem;
  line-height: 3.2rem;
  margin-top: 0;

  @media (max-width: 480px) {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  @media (max-width: 409px) {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`

export const HeroContent = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }

  a {
    font-weight: bold;
    padding-bottom: 4px;
    border-bottom: 2px solid black;
    transition: all 250ms ease-out;
  }
  a:active,
  a:hover {
    padding-bottom: 8px;
    border-bottom: 2px solid transparent;
  }
`

export const HeroText = HeroContent.withComponent("p")

type HeroProps = {
  emoji?: React.ReactNode
  title?: React.ReactNode
  content?: React.ReactNode
}

export default ({ emoji, title, content }: HeroProps) => (
  <HeroSection>
    {emoji && <HeroEmoji>{emoji}</HeroEmoji>}
    {title && <HeroTitle>{title}</HeroTitle>}
    {content && typeof content === "string" ? (
      <HeroText>{content}</HeroText>
    ) : (
      <HeroContent>{content}</HeroContent>
    )}
  </HeroSection>
)
