/**
 * Custom React Hook that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from "gatsby"

type SiteMetadata = {
  title: string
  description: string
  author: string
  // siteUrl: string
  // headline: string
  // image: string
  // video: string
  // twitter: string
  // name: string
  // logo: string
}

const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  return site.siteMetadata
}

export default useSiteMetadata
