import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { useLocation } from "@reach/router"
import { Link } from "gatsby"
import React from "react"

const StyledNavigation = styled.div``

const activeStyle = css`
  border-bottom: 2px solid #000;
  padding-bottom: 4px;
`

const StyledLink = styled(Link)`
  color: black;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  transition: all 250ms ease;

  &:not(:first-of-type) {
    margin-left: 20px;
  }

  &:hover {
    ${activeStyle}
  }
`

type NavigationProps = {}

const Navigation = ({}: NavigationProps) => {
  const { pathname } = useLocation()
  return (
    <StyledNavigation>
      <StyledLink to="/about" css={/^\/about/.test(pathname) && activeStyle}>
        about
      </StyledLink>
      {/* <StyledLink
        to="/contact"
        css={/^\/contact/.test(pathname) && activeStyle}
      >
        contact
      </StyledLink> */}
    </StyledNavigation>
  )
}

export default Navigation
