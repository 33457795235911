import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata"

type SEOProps = {
  description?: string
  lang?: string
  meta?: any[]
  link?: any[]
  title: string
}

const SEO = ({ description, lang, meta, link, title }: SEOProps) => {
  const siteMeta = useSiteMetadata()
  const metaDescription = description || siteMeta.description

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${siteMeta.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMeta.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        {
          href:
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap",
          rel: "stylesheet",
        },
      ].concat(link)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  link: [],
  description: ``,
}

export default SEO
