import styled from "@emotion/styled"
import React from "react"

import useSiteMetadata from "../hooks/use-site-metadata"
import Header from "./Header"
import Footer from "./Footer"
import { HeroSection } from "../styles/Hero"
import "./layout.css"

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  margin: 0 auto;
  max-width: 1400px;
  padding: 1rem 2rem;
  flex: 1;
  width: 100%;

  @media (max-width: 480px) {
    padding: 1rem;
  }

  @media (max-width: 480px) {
    ${HeroSection}:first-of-type {
      padding-top: 1rem;
    }
  }
`

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const { title } = useSiteMetadata()

  return (
    <Container>
      <Header siteTitle={title} />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

export default Layout
